import "@arenza/typefaces-museo-sans";
import PropTypes from "prop-types";
import React from "react";

import "../assets/main.scss";
import '@Development/ui-kit/dist/cjs/index.css';
import { Box } from "@material-ui/core";

import iconWhatsapp from '../assets/whatsapp.svg';
import BannerAuto from '../components/BannerAuto';
import LayoutAdaptiveWrapper from "./LayoutAdaptiveWrapper";
import LayoutApplicationContext from "./LayoutApplicationContext";
import LayoutNavigation from "./LayoutNavigation";

Layout.propTypes = {
  children: PropTypes.node,
};
export default function Layout({ children }) {
  return (
    <LayoutAdaptiveWrapper>
      <LayoutApplicationContext>
        <LayoutNavigation>{children}</LayoutNavigation>
      </LayoutApplicationContext>

      <a href={"https://wa.me/+74951254344?text=Здравствуйте,%20интересует%20лизинг."} rel="nofollow noreferrer" style={{ position: 'fixed', right: '14px', bottom: '14px', zIndex: 5 }} target="_blank">
        <Box component={"img"} src={iconWhatsapp} />
      </a>
      <BannerAuto />
    </LayoutAdaptiveWrapper>
  );
}
